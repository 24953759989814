import config from "./index.js";

const culqiConfig = (jsonParams) => {
  Culqi.publicKey = config.PUBLIC_KEY;
  Culqi.settings({
    currency: config.CURRENCY,
    amount: jsonParams.amount,
    title: 'REALIZAR PAGO', //Obligatorio para yape
    order: jsonParams.orderId,
    //xculqirsaid: config.RSA_ID,
    //rsapublickey: config.RSA_PUBLIC_KEY,
  });

  Culqi.options({
    lang: 'auto',
    installments: jsonParams.installments,
    paymentMethods: {
      tarjeta: true,
      yape: true,
      bancaMovil: true,
      agente: true,
      billetera: true,
      cuotealo: false,
    },
    style: {
      //logo: 'https://culqi.com/LogoCulqi.png',
      //logo: jsonParams.logo,
      bannerColor: jsonParams.topBG, // hexadecimal
      buttonBackground: jsonParams.btnBG, // hexadecimal
      menuColor: jsonParams.txtColor, // hexadecimal
      linksColor: jsonParams.txtColor, // hexadecimal
      buttonText: jsonParams.buttonTex, // texto que tomará el botón
      buttonTextColor: '#ffffff', // hexadecimal
      priceColor: jsonParams.priceColor // hexadecimal
    }
  });
}

export default culqiConfig;