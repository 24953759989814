import Service from "../index.js"
import config , { customerInfo } from "../../config/index.js"

const service = new Service();

export const generateOrderImpl = async ({account=null}) => {
  console.log("account", account);
  const bodyRequest = {
    amount: config.TOTAL_AMOUNT,
    currency_code: config.CURRENCY,
    description: 'Venta de prueba',
    order_number: 'Pedido-' +(new Date).getTime(),
    
    client_details: {
      first_name: customerInfo.firstName,
      last_name: customerInfo.lastName,
      email: account.email??null,
      phone_number: account.telefono_movil??null,
      
      id: account.cli_id??null,
      token: account.api_token_carrito??null,
    },
    /**/
  }

  return service.createOrder(bodyRequest);
}

export const generateCardImpl = async ({ customerId, email, tokenId, deviceId, parameters3DS = null }) => {
  const bodyRequest = {
    amount: config.TOTAL_AMOUNT,
    currency_code: config.CURRENCY,
    email: email,
    source_id: tokenId,
    customer_id: customerId,
    antifraud_details: {
      device_finger_print_id: deviceId,
    },
  }
  return service.createCard(parameters3DS ? { ...bodyRequest, authentication_3DS: { ...parameters3DS } } : bodyRequest);
}

export const generateChargeImpl = async ({tokenId, deviceId, email, parameters3DS = null, account=null, sucursal_operacion=null, cart_store=[]}) => {
  var data_fraud = {}
  var data = {
    amount : config.TOTAL_AMOUNT,
    currency_code : config.CURRENCY,
    email : email,
    source_id : tokenId,
    antifraud_details : data_fraud,

    account_id: account.cli_id??null,
    account_token: account.api_token_carrito??null,
    account_direccion:account.address??null,
    account_referencia:account.reference??null,
    account_ubigeo:account.ubigeo??null,
    
    sucursal:sucursal_operacion??null,
    cart_store:cart_store,
  };

  console.log("json", data);

  return service.createCharge(parameters3DS ? { ...data, authentication_3DS: { ...parameters3DS } } : data);
}