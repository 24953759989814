import config  from "../config/index.js"
class Service {
  #BASE_URL = config.URL_BASE;

  #http2 = async ({ endPoint, method = 'POST', body = {}, headers = {} }) => {
    let statusCode = 502; 

    const bodyRequest = {
      amount: body.amount,
      currency_code: body.currency_code,
      client_email: body.client_details.email??null,
      client_phone_number: body.client_details.phone_number??null,
      client_id: body.client_details.id??null,
      client_token: body.client_details.token??null,
    }

    try {
      const response = await $.ajax({
        type: 'POST',
        url: `${this.#BASE_URL}/${endPoint}`,
        data: bodyRequest,
        headers: {
          Authorization: "Bearer "+window.tokenAPI
        },
        success: function (data, status, xhr) {
          statusCode = xhr.status;
        }
      });

      const responseJSON = await response;

      return { statusCode: statusCode, data: responseJSON }
    } catch (err) {
      return { statusCode: statusCode, data: null, error: err.responseJSON.message??"" }
    }
  }

  #http3 = async ({ endPoint, method = 'POST', body = {}, headers = {} }) => {
    let statusCode = 502; 
    const authentication_3DS = body.authentication_3DS ? {
      eci: body.authentication_3DS.eci,
      xid: body.authentication_3DS.xid,
      cavv: body.authentication_3DS.cavv,
      protocolVersion: body.authentication_3DS.protocolVersion,
      directoryServerTransactionId: body.authentication_3DS.directoryServerTransactionId,
    } : null;

    const bodyRequest = {
      amount: body.amount,
      currency_code: body.currency_code,
      email: body.email,
      token: body.source_id,
      customer_id: body.customer_id,
      deviceId: body.antifraud_details.device_finger_print_id,

      account_id:body.account_id,
      account_token: body.account_token,
      account_direccion: body.account_direccion,
      account_referencia: body.account_referencia,
      account_ubigeo: body.account_ubigeo,
      account_tel_movil: body.telefono_movil??null,
      sucursal: body.sucursal,
      cart_store: body.cart_store,
      
      ...authentication_3DS,
    }
    try {
      const response = await $.ajax({
        type: 'POST',
        url: `${this.#BASE_URL}/${endPoint}`,
        data: bodyRequest,
        headers: {
          Authorization: "Bearer "+window.tokenAPI
        },
        success: function (data, status, xhr) {
          statusCode = xhr.status;
        }        
      });

      const responseJSON = await response;
      return { statusCode: statusCode, data: responseJSON, error: err.responseJSON.msg??"" }
    } catch (err) {
      let error="";
      if(err.responseJSON && err.responseJSON.msg){
        error = err.responseJSON.msg;
      }else{
        console.log(err);
      }
      return { statusCode: statusCode, data: null, error: error }
    }
  }

  createOrder = async (bodyOrder) => {
    return this.#http2({ endPoint: "generar_orden", body: bodyOrder });
  }
  createCharge = async (bodyCharge) => {
    return this.#http3({ endPoint: "establecer_carga", body:bodyCharge});
  }
}
export default Service;
