export default Object.assign({
  TOTAL_AMOUNT: ($("#valueCargo").val()) *100, // se multiplica por 100. si se envia 5 debe evniar 500
  CURRENCY: "PEN",
  PUBLIC_KEY: window.tokenCulqui,
  RSA_ID: 'de35e120-e297-4b96-97ef-10a43423ddec',
  RSA_PUBLIC_KEY: '-----BEGIN PUBLIC KEY-----'+
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDswQycch0x/7GZ0oFojkWCYv+g'+
  'r5CyfBKXc3Izq+btIEMCrkDrIsz4Lnl5E3FSD7/htFn1oE84SaDKl5DgbNoev3pM'+
  'C7MDDgdCFrHODOp7aXwjG8NaiCbiymyBglXyEN28hLvgHpvZmAn6KFo0lMGuKnz8'+
  'HiuTfpBl6HpD6+02SQIDAQAB'+
  '-----END PUBLIC KEY-----',
  COUNTRY_CODE: "PE",
  URL_BASE: window.baseUrl+"tiendaVirtual",
});

export const customerInfo = {
  firstName: "Fernando",
  lastName: "Chullo",
  address: "Coop. Villa el Sol",
  address_c: "Palo Alto",
  phone: "945737476",
  email: "review1" + Math.floor(Math.random() * 100) + "@culqi.com",
};
