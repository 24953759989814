<template>
    <div class="toolbar_main">
        <div class="osahan-breadcrumb">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a :href="'/tienda/'+sucursal_seleccionada"><i class="icofont icofont-ui-home"></i> Inicio</a></li>
                            <li class="breadcrumb-item active">Carrito de compra</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section v-if="(!this.mi_cuenta.cli_id || !this.mi_cuenta.api_token_carrito)" class="text-center">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <p class="text-center fw-bold  " style="font-size: 1.5rem;color:black;">Bienvenido a tienda virtual "{{empresa.razon_social}}"</p>
                        <p class="text-center mb-1 " style="font-size: 1rem;margin-bottom: 1rem;">Debe ingresar al sistema para poder ver su <b>carrito de compras</b></p>
                        <img style="text-align: center;margin-top: 1rem;width: 5rem;" class="mb-3" src="/img/actualizado.png">
                        <p class="text-center mb-1 " style="font-size: 1rem;margin-bottom: 1rem;">Lo vamos a redirigir a la tienda para que disfrute de los mejores y más modernos productos dentro de {{segundos_refrescar/1000}} segundos</p>
                    </div>
                </div>
            </div>
        </section>

        <div v-else class="container pb-3 mb-2">
            <div class="row">
                <div class="col-lg-12">
                    <form-wizard ref="formwizard" @onCompleteNext="onCompleteNext" @onNextStep="nextStep" @onPreviousStep="previousStep" @onResetAll="resetAll">
                        <tab-content title="Detalle Orden" :selected="true">
                            <div class="row">
                                <div class="col-lg-8 col-md-8">
                                    <div class="widget">
                                        <div class="section-header">
                                            <h3 class="heading-design-h5">
                                                Detalle orden
                                                <a class="btn pull-right" :href="'/catalogo/'+sucursal_seleccionada+'?pag=1'"><i class="icofont icofont-shopping-cart"></i> Seguir comprando</a>
                                            </h3>
                                        </div>

                                        <div class="table-responsive">
                                            <div v-if="!peticion_enviada" class="text-center">
                                                <i class="fa fa-spinner fa-spin fa-4x fa-fw"></i>
                                                <h1  style="margin-top: 1rem;">Cargando</h1>
                                            </div>
                                            <div v-else>
                                                <div v-if="bloquear_seccion_carrito" >
                                                    <div class="mb-0 p-2 text-justify">
                                                        <p class="text-danger" v-if="!pago_autorizado">
                                                            <i class="fa fa-wrench mr-2" style="color:black;" aria-hidden="true"></i>No se realizó la configuración de la pasarela de pagos con Culqi.
                                                        </p>
                                                        <p class="text-danger" v-if="!tipo_igv_autorizado">
                                                            <i class="fa fa-wrench mr-2" style="color:black;" aria-hidden="true"></i>No se realizó la configuración del tipo pago IGV para la Facturación electrónica, el equipo de SOPORTE está trabajando en ello.
                                                        </p>
                                                        <p class="text-danger" v-if="!grupo_igv_autorizado">
                                                            <i class="fa fa-wrench mr-2" style="color:black;" aria-hidden="true"></i>No se realizó la configuración del grupo IGV para la Facturación electrónica, el equipo de SOPORTE está trabajando en ello.
                                                        </p>
                                                        <p class="text-danger" v-if="!sucursal_autorizado">
                                                            <i class="fa fa-wrench mr-2" style="color:black;" aria-hidden="true"></i>No se realizó la configuración de la sucursal.
                                                        </p>
                                                        <p class="text-danger" v-if="!almacen_autorizado">
                                                            <i class="fa fa-wrench mr-2" style="color:black;" aria-hidden="true"></i>No se realizó la configuración del almacen de la sucursal <b>{{sucursal_seleccionada}}</b>.
                                                        </p>
                                                        <p class="text-danger" v-if="!mov_almacen_autorizado">
                                                            <i class="fa fa-wrench mr-2" style="color:black;" aria-hidden="true"></i>No se realizó la configuración del movimiento de almacen.
                                                        </p>
                                                        <p class="text-danger" v-if="!tipo_transaccion_autorizado">
                                                            <i class="fa fa-wrench mr-2" style="color:black;" aria-hidden="true"></i>No se realizó la configuración de tipo operación transacción.
                                                        </p>
                                                        <p class="text-danger" v-if="!etapa_realizado_autorizado">
                                                            <i class="fa fa-wrench mr-2" style="color:black;" aria-hidden="true"></i>No se realizó la configuración de las estapas de venta (REALIZADO).
                                                        </p>
                                                        <p class="text-danger" v-if="!correlativo_comprobante_configurado">
                                                            <i class="fa fa-wrench mr-2" style="color:black;" aria-hidden="true"></i>No se realizó la configuración del correlativo para las BOLETAS DE VENTA ELECTRONICO, para la sucursal <b>{{sucursal_seleccionada}}</b>.
                                                        </p>
                                                        <p class="text-danger" v-if="!cliente_direccion_autorizado">
                                                            <i class="fa fa-wrench mr-2" style="color:black;" aria-hidden="true"></i>Estimado cliente, debe ingresar su dirección, haciendo clic <a href="/perfil"><b>AQUÍ</b></a>.
                                                        </p>
                                                        <p class="text-danger" v-if="!cliente_ubigeo_autorizado">
                                                            <i class="fa fa-wrench mr-2" style="color:black;" aria-hidden="true"></i>Estimado cliente, debe ingresar su ubigeo, haciendo clic <a href="/perfil"><b>AQUÍ</b></a>.
                                                        </p>
                                                        <p class="text-danger" v-if="!cliente_telmovil_autorizado">
                                                            <i class="fa fa-wrench mr-2" style="color:black;" aria-hidden="true"></i>Estimado cliente, debe ingresar su número de teléfono móvil, haciendo clic <a href="/perfil"><b>AQUÍ</b></a>.
                                                        </p>
                                                    </div>

                                                    <center>
                                                        <i class="fa fa-terminal mr-4" style="font-size: 7rem;" aria-hidden="true"></i>
                                                    </center>

                                                    <h1  style="margin-top: 1rem;">Disculpe las molestias, estamos configurando nuestra plataforma para que se lleve la mejor de las experiencias</h1>
                                                </div>
                                                <div v-else>
                                                    <table v-if="$store.state.carrito.length>0 && pago_autorizado && correlativo_comprobante_configurado" class="table cart_summary mb-1">
                                                        <thead>
                                                            <tr>
                                                                <th class="pt-1 pb-1">Producto</th>
                                                                <th class="pt-1 pb-1">Descripción</th>
                                                                <th class="pt-1 pb-1">Precio</th>
                                                                <th class="pt-1 pb-1">Cant.</th>
                                                                <th class="pt-1 pb-1">Total</th>
                                                                <th class="pt-1 pb-1" style="width: 10px;">#</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr v-for="(val, key) in $store.state.carrito" :key="key">
                                                                <td class="cart_product pt-1 pb-1 ">
                                                                    <a :href="'/producto/'+sucursal_seleccionada+'/'+val.prod_id">
                                                                        <img class="img-fluid" :src="val.url_imagen" alt="Producto">
                                                                    </a>
                                                                </td>
                                                                <td class="cart_description pt-1 pb-1 ">
                                                                    <p class="product-name">
                                                                        <a :href="'/producto/'+sucursal_seleccionada+'/'+val.prod_id">{{val.nombre_producto}}</a>
                                                                    </p>
                                                                    <small>
                                                                        <a :href="'/producto/'+sucursal_seleccionada+'/'+val.prod_id"> SKU: {{val.cod_articulo}}</a>
                                                                    </small>
                                                                </td>   
                                                                <td class="price pt-1 pb-1 ">
                                                                    <span>S/ {{number_format(val.precio_venta, 2, '.', ',')}}</span>
                                                                </td>
                                                                <td class="qty pt-1 pb-1">
                                                                    <button type="button" class="btn btn-xs cursor-pointer" :disabled="val.cantidad<=1" @click="agregarCarrito(val, 1, false, false)" style="margin-right: 5px; border:1px solid black; border-radius:100%;"> - </button>
                                                                    <span class="badge bg-info">{{val.cantidad}}</span>
                                                                    <button type="button" class="btn btn-xs cursor-pointer" :disabled="val.cantidad>=val.stock" @click="agregarCarrito(val, 1, false, true)" style="margin-left: 5px; border:1px solid black; border-radius:100%;"> + </button>
                                                                </td>
                                                                <td class="price pt-1 pb-1 " >
                                                                    <span>S/ {{number_format(val.precio_venta*val.cantidad, 2, '.', ',')}}</span>
                                                                </td>
                                                                <td class="action pt-1 pb-1" style="width: 10px;">
                                                                    <a data-toggle="tooltip" @click.prevent="borrarItemCarrito(val)" data-placement="top" title href="javascript:void(0);" data-original-title="Eliminar del carrito">
                                                                        <i class="fa fa-trash-o"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>

                                                        <tfoot>
                                                            <tr>
                                                                <td colspan="3" class="pt-1 pb-1" ><strong>Total</strong></td>
                                                                <td colspan="2" class="pt-1 pb-1" ><strong>S/ {{number_format(totalVenta, 2)}} </strong></td>
                                                                <td class="pt-1 pb-1"></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                    <div v-else class="row" style="margin-bottom: 5rem;">
                                                        <div class="col-lg-12" style="text-align: center;">
                                                            <i class="fa fa-opencart  " style="font-size: 5rem;"></i>

                                                            <h1  style="margin-top: 1rem;">Tu carrito de compras esta vacío </h1>

                                                            <a :href="'/tienda/'+sucursal_seleccionada" class="btn btn-outline-danger btn-lg" style="margin-top: 1.5rem;">Ir a la tienda</a>
                                                        </div>
                                                    </div>

                                                    <div v-if="$store.state.carrito.length>0" class="form-group">
                                                        <div class="alert alert-info mb-0 p-2">
                                                            <b>Nota</b>:
                                                            <ol class="mb-0" style="font-size:12px;">
                                                                <li>El monto de compra debe ser mayor a S/ 0.00.</li>
                                                                <li>Para pagos YAPE, el monto debe ser mayor a S/ 6.00 y menor a S/ 950.00.</li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-lg-4 col-md-4">
                                    <div class="widget mb18">
                                        <div class="card">
                                            <div class="card-header">Datos de envio y facturación</div>
                                            <div class="card-body">
                                                <div class="card-text"><strong>Cliente</strong></div>
                                                <div class="card-text mb-1">
                                                    {{$store.state.mi_cuenta.nombres}}
                                                </div>
                                                <div class="card-text"><strong class="required">Dirección</strong></div>
                                                <div class="mb-1">
                                                    <textarea type="text" class="form-control" v-model="misDatos.direccion" placeholder="Jr. Jimenez Pimentel #300" ></textarea>
                                                </div>
                                                <div class="card-text"><strong class="">Referencia</strong></div>
                                                <div class="mb-1">
                                                    <input type="text" class="form-control input-xs" v-model="misDatos.referencia_direccion" placeholder="Al costado de la RENIEC">
                                                </div>
                                                <div class="card-text"><strong class="required">Ubigeo</strong></div>
                                                <div class="mb-1">
                                                    <div class="input-group input-group-xs">
                                                        <input class="form-control input-xs" v-model="misDatos.ubigeo" ref="ubigeo" placeholder="Departamento - Provincia - Distrito" readonly type="text">
                                                        <div class="input-group-btn">
                                                            <button type="button" class="btn btn-danger btn-xs cursor-pointer" ref="btn_ubigeo" @click="abrir_ubigeo" >
                                                                <i class="fa fa-search"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="alert alert-warning mb-0 p-2" style="font-size:11px;">
                                                    Para continuar llene los datos obligatorios (<span class="text-danger text-bold">*</span>), o haciendo clic <a href="/perfil">aquí</a>.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab-content>

                        <tab-content title="Finalizar">
                            <div class="widget">
                                <div class="order-detail-form text-center">
                                    <div class="col-lg-10 col-md-10 mx-auto order-done">
                                        <i class="icofont icofont-check-circled"></i>
                                        <h2 class="text-success">¡Felicitaciones! Su pedido ha sido aceptado..</h2>
                                        <p>
                                            En lo pronto estaremos validando su pedido. Nos pondremos en contacto con usted...
                                        </p>
                                    </div>
                                    <div class="cart_navigation text-center">
                                        <a :href="'/catalogo/'+sucursal_seleccionada+'?pag=1'" class="btn btn-theme-round">
                                            <i class="icofont icofont-shopping-cart"></i> Seguir comprando
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </tab-content>
                    </form-wizard>
                </div>
            </div>
        </div>

        <Ubigeo ref="form_ubigeo" :title_modal="'Seleccione Ubigeo'" @dataUbigeo="ubigeo_seleccionado"></Ubigeo>
    </div>
</template>

<script>
    let jsonParams  ;
    const paymenType = "cargo";
    let tokenId, email, deviceId;

    import config from "@/assets/js-culqi/config/index.js";
    import culqiConfig from "@/assets/js-culqi/config/checkout.js";
    import "@/assets/js-culqi/config/culqi3ds.js";
    import { generateOrderImpl, generateChargeImpl } from "@/assets/js-culqi/services/impl/index.js";

    import FormWizard from '@/components/FormWizard.vue';
    import TabContent from '@/components/TabContent.vue';
    import Ubigeo from '@/components/Ubigeo.vue';

    import {mapState, mapMutations, mapActions} from 'vuex';    
    

    export default {
        name: 'carrito',
        computed:{
            ...mapState(['empresa', 'mi_cuenta', 'sucursales', 'sucursal_seleccionada']),
            totalVenta() {
                let total = 0;

                for (let item of this.$store.state.carrito) {
                total += item.precio_venta*item.cantidad;
                }
                return total.toFixed(2);
            }
        },
        components:{
            FormWizard,
            TabContent,
            Ubigeo,
        },
        created(){
            this.loadObjSucursalSeleccionada();
            this.loadObjPerfilUsuario();
        },
        props: {
            datosPerfil: {},
        },
        mounted(){
            if(!this.$store.state.mi_cuenta.cli_id || !this.$store.state.mi_cuenta.api_token_carrito){
                this.cerrar_sesion();
                this.cuenta_regresiva();
                toastr.warning('Debe ingresar al sistema para poder ver su carrito de compras', 'Acceso restringido');
                return;
            }

            this.cargarMisDatos();
            document.title = "Mi Carrito | "+((this.empresa.abreviatura)?this.empresa.abreviatura:"-");
        },
        data(){
            return{
                misDatos:{
                    cli_id: Boolean(this.$store.state.mi_cuenta.cli_id)?this.$store.state.mi_cuenta.cli_id:null,
                    api_token_carrito:Boolean(this.$store.state.mi_cuenta.api_token_carrito)?this.$store.state.mi_cuenta.api_token_carrito:null,
                    razon_social:"No definido",
                    email:"anonimo@example.com",
                    direccion:"",
                    referencia_direccion:"",
                    ubigeo:"",
                    cod_ubigeo:"",
                    carrito:[],
                    total_venta:0,
                    token:null,
                },
                errores:[],
                id_ubigeo: "",
                segundos_refrescar: 10000,
                moneda: "gRgA76mU",     //El valor es id_encrypt, de la tabla moneda
                tipo_comprobante:"03",  //El valor es de codsunat, de la tabla tipo_comprobante
                peticion_enviada: false,
                pago_autorizado: false, //Identifica si fue declarado el "secret_key_culqi" en el backend
                correlativo_comprobante_configurado: false, //Identifica que la serie y correlativo está configurado para generar los comprobantes de venta
                sucursal_autorizado: false,
                almacen_autorizado: false,
                tipo_igv_autorizado: false,
                grupo_igv_autorizado: false,
                mov_almacen_autorizado: false,
                tipo_transaccion_autorizado: false,
                etapa_realizado_autorizado: false,
                cliente_direccion_autorizado: false,
                cliente_ubigeo_autorizado: false,
                cliente_telmovil_autorizado: false,

                bloquear_seccion_carrito: true,
            }
        },
        methods:{
            ...mapMutations(['setLoading', 'setCarritocliente']),
            ...mapActions(['loadObjPerfilUsuario', 'loadObjSucursalSeleccionada']),
            cerrar_sesion(){
                this.$store.commit('borrarSesion');
            },
            cuenta_regresiva(){
                setInterval(() => {
                    this.segundos_refrescar = this.segundos_refrescar - 1000;
                    if(this.segundos_refrescar==0){
                        window.location.href = "/tienda/"+this.sucursal_seleccionada;
                        return;
                    }
                }, 1000);
            },
            abrir_ubigeo(){
                this.$refs.form_ubigeo.open_ubigeo();
            },
            ubigeo_seleccionado(objUbigeo){
                this.misDatos.cod_ubigeo    = objUbigeo.id_ubigeo;
                this.misDatos.ubigeo   = objUbigeo.ubigeo;

                this.validarSiguienteTab();
            },
            cargarMisDatos(){
                let selfs = this;
                let formData = new FormData();
                    formData.append("cli_id", this.$store.state.mi_cuenta.cli_id);
                    formData.append("cli_api_token", this.$store.state.mi_cuenta.api_token_carrito);
                    formData.append("sucursal", this.sucursal_seleccionada);

                selfs.setLoading(true);
                
                selfs.$http.post('tiendaVirtual/perfil', formData).then((res)=>{
                    if(res.data.status){
                        this.peticion_enviada                   = true;
                        this.misDatos                           = res.data.data;

                        this.pago_autorizado                    = res.data.authorized_payment;
                        this.correlativo_comprobante_configurado= res.data.authorized_currentvalue_voucher_type;
                        this.tipo_igv_autorizado                = res.data.authorized_afectation_igv_type;
                        this.grupo_igv_autorizado               = res.data.authorized_group_igv;
                        this.sucursal_autorizado                = res.data.authorized_sucursal;
                        this.almacen_autorizado                 = res.data.authorized_almacen;
                        this.mov_almacen_autorizado             = res.data.authorized_mov_almacen;
                        this.tipo_transaccion_autorizado        = res.data.authorized_transaction_type;
                        this.etapa_realizado_autorizado         = res.data.authorized_operation_stage;
                        this.cliente_direccion_autorizado       = res.data.authorized_client_address;
                        this.cliente_ubigeo_autorizado          = res.data.authorized_client_ubigeo;
                        this.cliente_telmovil_autorizado        = res.data.authorized_client_mobile;

                        this.setCarritocliente(res.data.data.carrito);
                        this.bloquearSeccionCarrito();

                        this.validarSiguienteTab();
                    }else{
                        this.cerrar_sesion();

                        window.location.href = "/tienda/"+this.sucursal_seleccionada;
                        return;
                    }

                    setTimeout(()=>{
                        selfs.setLoading(false);
                        $('[data-toggle="tooltip"]').tooltip();
                    }, 500);
                }).catch(function (e) {
                    if(e && e.response && e.response.status==422){ //Errores de Validacion
                        const mapErrors ={};
                        selfs.errorSolicitud = [];
                        selfs.input_email=false;
                        for (let field of Object.keys(e.response.data.errors)) {
                            mapErrors[field]=e.response.data.errors[field][0];
                            selfs.errorSolicitud.push(mapErrors[field]);
                        }
                    }
                });
            },
            async onCompleteNext(){
                let selfs = this;

                this.misDatos.carrito     = this.$store.state.carrito;
                this.misDatos.total_venta = this.totalVenta;
                
                if(selfs.misDatos.ubigeo=="")
                    selfs.errores.push("Seleccione el Ubigeo del cliente");

                if(selfs.totalVenta<1)
                    selfs.errores.push("El total de la venta debe ser mayor a cero");

                if(selfs.misDatos.carrito.length<1)
                    selfs.errores.push("Debe ingresar productos al carrito");
                else{
                    selfs.misDatos.carrito.map(function(value, key) {
                        if(value.stock<1)
                            selfs.errores.push("No hay stock disponible para el producto "+value.nombre_producto_completo);
                        else if(value.cantidad>value.stock)
                            selfs.errores.push("La cantidad solicitada del producto "+value.nombre_producto_completo+", excede del stock");
                    });
                }
                    
                if(this.errores.length>0){
                    this.errores.map(function(value, key) {
                        toastr.warning(value,'Advertencia');
                    });
                    return;
                }

                this.initOrden(paymenType);
            },
            nextStep(tab){
                console.log("tab", tab);
            },
            previousStep(){
                //console.log("On Previous Step");
            },
            resetAll(){
                location.reload();
            },
            agregarCarrito(producto, cantidad, reemplazar_cantidad, incremento){
                if(!this.$store.state.mi_cuenta.cli_id){
                    this.$refs.form_login.abrir_login();
                    return;
                }
                
                if(producto.stock>=cantidad && producto.stock>0){
                    this.validarSiguienteTab();

                    this.$store.commit('agregarCarrito',{
                        producto:producto,
                        cantidad:cantidad,
                        reemplazar_cantidad: reemplazar_cantidad,
                        sucursal: this.sucursal_seleccionada,
                        incremento: incremento
                    });
                }else{
                    toastr.warning('No hay stock disponible para este producto', 'Mensaje');
                }
            },
            borrarItemCarrito(item) {
                this.$store.commit('borrarItemCarrito', item);
                this.validarSiguienteTab();
            },
            validarSiguienteTab(){
                let carrito_verificacion  = this.$store.state.carrito;
                let botonHabilitado       = true;
                
                if(this.misDatos.direccion==""){
                    botonHabilitado       = false;
                }

                if(this.misDatos.referencia_direccion==""){
                    botonHabilitado       = false;
                }

                if(this.misDatos.cod_ubigeo==""){
                    botonHabilitado       = false;
                }

                if(carrito_verificacion.length<1)
                    botonHabilitado       = false;

                if(this.bloquear_seccion_carrito)
                    botonHabilitado       = false;
                
                carrito_verificacion.map((val, key)=>{
                    if(val.stock<0)
                        botonHabilitado = false;
                    else if(val.cantidad>val.stock)
                        botonHabilitado = false;
                })

                this.$refs.formwizard.changeButtonNext(botonHabilitado);
            },

            async initOrden(paymenType=null){
                let selfs = this;
                selfs.setLoading(true);

                config.TOTAL_AMOUNT=(this.totalVenta) *100;
                jsonParams = {
                    //installments: paymenType === "cargo" ? true : false,
                    installments: false,
                    orderId: await this.generarOrder(),
                    buttonTex: '',
                    amount : config.TOTAL_AMOUNT,
                    paymentMethods: {
                        tarjeta: true,
                        bancaMovil: true,
                        agente: true,
                        billetera: true,
                        cuotealo: true,
                        yape: true,
                    },
                    logo: this.empresa.logo,
                    topBG: '#DC3545',
                    btnBG: "#DC3545",
                    txtColor: "#d72b27",
                    priceColor: "#000000"
                };
                
                culqiConfig(jsonParams);
                deviceId = await Culqi3DS.generateDevice();
                if(jsonParams.orderId){
                    Culqi.validationPaymentMethods();
                    console.log(Culqi.paymentOptionsAvailable);
                    Culqi.open();
                    this.culqiListen();
                    selfs.setLoading(false);
                }
            },
            async generarOrder(){
                let selfs = this;
                let account = {
                    cli_id: selfs.$store.state.mi_cuenta.cli_id,
                    api_token_carrito:selfs.$store.state.mi_cuenta.api_token_carrito,
                    address: selfs.misDatos.direccion,
                    reference: selfs.misDatos.referencia_direccion,
                    ubigeo:selfs.misDatos.cod_ubigeo,
                    telefono_movil: selfs.misDatos.telefono_movil??null,
                    email: selfs.misDatos.email??null,
                };

                const { statusCode, data, error } = await generateOrderImpl({account});
                if (statusCode === 201) {
                    return data.id;
                } else {
                    selfs.setLoading(false);
                    if(error){
                        toastr.warning(error,'Error');   
                    }else{
                        toastr.warning(data.msg,'Error');
                    }
                    this.$refs.formwizard.changeButtonNext(false);
                }
                return '';
            },
            culqiListen(){
                let selfs = this;
                let account = {
                    cli_id: selfs.$store.state.mi_cuenta.cli_id,
                    api_token_carrito:selfs.$store.state.mi_cuenta.api_token_carrito,
                    address: selfs.misDatos.direccion,
                    reference: selfs.misDatos.referencia_direccion,
                    ubigeo:selfs.misDatos.cod_ubigeo,
                    telefono_movil: selfs.misDatos.telefono_movil??null,
                    email: email,
                };
                let sucursal_operacion = this.sucursal_seleccionada;
                let cart_store = selfs.$store.state.carrito;
                selfs.$refs.formwizard.changeButtonNext(false);

                window.addEventListener("message", async function (event) {
                    console.log("event:", event);

                    selfs.setLoading(true);
                    if (event.origin === window.location.origin) {
                        const { parameters3DS, error } = event.data;

                        if (parameters3DS) {
                            let statusCode = null;

                            if (paymenType === "cargo") {
                                const responseCharge = await generateChargeImpl({ tokenId, deviceId, email, parameters3DS, account, sucursal_operacion, cart_store });
                                statusCode = responseCharge.statusCode;
                                console.log(" ===>",responseCharge.error);
                                if (statusCode === 201) {
                                    console.log("CARGO CREADO CON ÉXITO");
                                    this.setCarritocliente([]);
                                    selfs.$refs.formwizard.changeButtonNext(true);
                                    selfs.$refs.formwizard.changeStatus();
                                    selfs.$refs.formwizard.nextTab();
                                    Culqi3DS.reset();
                                } else {
                                    selfs.$refs.formwizard.changeButtonNext(false);
                                    toastr.warning(responseCharge.error,'Error '+statusCode);
                                    Culqi3DS.reset();
                                }
                            }else{
                                //console.log("paymenType dont cargo");
                            }
                        }
                        
                        if (error) {
                            toastr.warning(error,'Error');
                            console.log("Ocurrió un error: ", error);
                        }
                    }
                    selfs.setLoading(false);
                },
                    false
                );

                window.culqi = async () => {
                    selfs.setLoading(true);

                    if (Culqi.token) {
                        Culqi.close();

                        tokenId = Culqi.token.id;
                        email = Culqi.token.email;
                        let parameters3DS_ = null;

                        if (paymenType === "cargo") {
                            const { statusCode, data, error } = await generateChargeImpl({tokenId, deviceId, email, parameters3DS_, account, sucursal_operacion, cart_store });
                            
                            if (statusCode === 200) {
                                if(data.action_code === "REVIEW"){
                                    this.validationInit3DS({ statusCode, email, tokenId });
                                }
                            } else if (statusCode === 201) {
                                this.setCarritocliente([]);

                                selfs.$refs.formwizard.changeButtonNext(true);
                                selfs.$refs.formwizard.changeStatus();
                                selfs.$refs.formwizard.nextTab();

                                console.log("CARGO EXITOSO - SIN 3DS");
                                Culqi3DS.reset();
                            } else {
                                selfs.$refs.formwizard.changeButtonNext(false);

                                toastr.warning(error,'Error '+statusCode);
                                Culqi3DS.reset();
                            }
                        }else{
                            console.log("paymenType dont cargo");
                        }
                    } else {
                        console.log(Culqi.error.user_message, Culqi.error.merchant_message);
                    }

                    selfs.setLoading(false);
                }
            },
            validationInit3DS({ statusCode, email, tokenId }){
                console.log(window.location.origin, config.URL_BASE);
                Culqi3DS.settings = {
                    charge: {
                        totalAmount: config.TOTAL_AMOUNT,
                        returnUrl: window.location.origin,
                    },
                    card: {
                        email: email,
                    }
                };
                Culqi3DS.initAuthentication(tokenId);
            },
            bloquearSeccionCarrito(){
                if(!this.correlativo_comprobante_configurado 
                    || !this.pago_autorizado 
                    || !this.sucursal_autorizado 
                    || !this.almacen_autorizado 
                    || !this.mov_almacen_autorizado 
                    || !this.tipo_transaccion_autorizado 
                    || !this.etapa_realizado_autorizado
                    || !this.tipo_igv_autorizado
                    || !this.grupo_igv_autorizado

                    || !this.cliente_direccion_autorizado
                    || !this.cliente_ubigeo_autorizado
                    || !this.cliente_telmovil_autorizado
                ){
                    this.bloquear_seccion_carrito = true;
                }else{
                    this.bloquear_seccion_carrito = false;
                }
            },
        },
    }
</script>

<style scoped>
    .error-page-main{
      background-color: #a32121;
    }

    .cursor-default{
        cursor: default;
    }
</style>